import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faSchool, faBars } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import BASE_URL from '../components/Baseurl';
import userimg from '../components/img/img5.jpg';

export default function Teambackend() {
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        // Fetch team details from the API
        const fetchTeamDetails = async () => {
            try {
                const response = await axios.get(`${BASE_URL}fetch-team.php`);
                if (response.data.status === 'success') {
                    setTeamMembers(response.data.data); // Assuming response data contains team members
                } else {
                    console.error('Failed to fetch team details:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchTeamDetails();
    }, []);

    return (
        <section>
            <div className="container">
                <div className="row mt-5">
                    <h4 className="h33 hover-underline-animation">Our Team</h4>
                    <div className="row text-center">
                        {teamMembers.map((member, index) => (
                            <div key={index} className="col-md-3 mb-4">
                                <img src={`${BASE_URL}/backend/profile/${member.profile_image}`} className="img3 mb-3" alt={member.name} />
                                <h5 className="hts">{member.name}</h5>
                                <p className="hts2">{member.profile_name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}