import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Business() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Business Setup & Collaboration</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Fostering Business Excellence: Setup and Collaboration Initiatives</h4>
                <div className=""></div>
                <p>Recognizing the entrepreneurial spirit within the (NRI) community, NRIWA is committed to facilitating seamless business setup and fostering collaborations for NRIs looking to invest or establish ventures in India. Our initiatives aim to provide guidance, networking opportunities, and resources for the successful initiation and growth of businesses.</p>
            

                <h4 className="h33">Our Business Setup and Collaboration Initiatives:</h4>

                <p><b>1. Business Incubation Programs:</b></p>
                <ul>
                    <li>NRIWA establishes business incubation programs to nurture startups and innovative ventures. These programs provide mentorship, resources, and a conducive environment for business growth.</li>
                </ul>
                <p><b>2. Networking Events and Conferences:</b></p>
                <ul>
                    <li>Host networking events and business conferences that bring together NRIs, industry leaders, and experts. NRIWA provides a platform for collaboration, knowledge exchange, and potential business partnerships.</li>
                </ul>
                <p><b>3. Regulatory Guidance:</b></p>
                <ul>
                    <li>Offer regulatory guidance to NRIs navigating the legal landscape of business setup in India. NRIWA ensures that NRIs are well-informed about the regulatory requirements, compliance procedures, and business registration processes.</li>
                </ul>

                <h4 className="h33">How NRIWA Supports Business Setup and Collaboration:</h4>

                <p><b>1. Business Consultation Services:</b></p>
                <ul>
                    <li>NRIWA offers business consultation services, connecting NRIs with experienced business consultants and advisors. These experts provide insights on market entry strategies, business planning, and growth projections.</li>
                </ul>
                <p><b>2. Entrepreneurial Workshops:</b></p>
                <ul>
                    <li>Conduct entrepreneurial workshops covering various aspects of business setup, management, and growth. NRIWA aims to equip NRIs with the knowledge and skills necessary for successful entrepreneurship.</li>
                </ul>
                <p><b>3. Investment Platforms:</b></p>
                <ul>
                    <li>Establish investment platforms that match NRIs seeking business opportunities with potential investors. NRIWA acts as a bridge between entrepreneurs and investors, facilitating the flow of capital for business growth.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for Business Excellence:</h4>
                <p>If you aspire to venture into business in India or seek collaborations for your existing business, NRIWA is here to support you. Join our community to access valuable resources, connect with industry professionals, and embark on a journey of entrepreneurial success.</p>
                <p>NRIWA - Fostering Business Excellence: Setup and Collaboration Initiatives</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

