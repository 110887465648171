import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Healthcare() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Healthcare</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Caring for Your Well-Being Across Borders</h4>
                <div className=""></div>
                <p>At NRIWA, we recognize the paramount importance of health and well-being, especially for Non-Resident Indians (NRIs) residing abroad. Our organization is committed to providing comprehensive healthcare support to NRIs, ensuring that they can access quality medical services and support, irrespective of their geographical location.</p>
            

                <h4 className="h33">Our Health Care Initiatives:</h4>

                <p><b>1. Telehealth Services:</b></p>
                <ul>
                    <li>NRIWA understands the challenges NRIs face in accessing healthcare services from abroad. Our telehealth services bring the expertise of Indian medical professionals.</li>
                    <li>Consult with experienced doctors, receive medical advice, and even schedule virtual appointments for more in-depth discussions about your health concerns.</li>
                </ul>
                <p><b>2. Medical Tourism Assistance:</b></p>
                <ul>
                    <li>Planning a visit to India for medical purposes? NRIWA assists NRIs in navigating the Indian healthcare system, connecting them with renowned hospitals and specialized medical professionals.</li>
                    <li>Benefit from our network to make informed decisions about healthcare procedures, surgeries, and wellness programs available in India.</li>
                </ul>
                <p><b>3. Health Insurance Guidance:</b></p>
                <ul>
                    <li>NRIWA provides guidance on health insurance options available in India, ensuring that NRIs have access to comprehensive coverage that meets their specific needs.</li>
                    <li>Our experts help you understand policy details, coverage benefits, and assist in selecting the right health insurance plan for you and your family.</li>
                </ul>

                <h4 className="h33">How NRIWA Supports Your Health:</h4>

                <p><b>1.	Virtual Health Consultations:</b></p>
                <ul>
                    <li>Connect with qualified healthcare professionals through our virtual platform to discuss your health concerns, seek advice, and receive personalized medical guidance.</li>
                </ul>
                <p><b>2.	Medical Concierge Services:</b></p>
                <ul>
                    <li>Planning a visit to India for medical treatment? Our medical concierge services assist you in making travel arrangements, scheduling appointments, and ensuring a smooth healthcare experience.</li>
                </ul>
                <p><b>3.	Health and Wellness Webinars:</b></p>
                <ul>
                    <li>Stay informed about the latest health trends, wellness practices, and preventive care through our informative webinars conducted by healthcare experts.</li>
                </ul>

                <p><b>4.	Emergency Medical Assistance:</b></p>
                <ul>
                    <li>NRIWA is here for you during medical emergencies. Our team can help coordinate emergency medical services in India, ensuring timely and efficient assistance.</li>
                </ul>


                <h4 className="h33">Contact NRIWA Today for Your Health Care Needs:</h4>
                <p>Your health is our priority, regardless of where you are in the world. NRIWA is dedicated to providing comprehensive health care support for NRIs, fostering a sense of security and well-being. Connect with us today to explore our health care services and embark on a journey towards a healthier and happier life.</p>
                <p>NRIWA - Caring for Your Well-Being Across Borders</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

