import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from '../components/Baseurl';

const ImageSlider = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        // Fetch images from the API using Axios
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${BASE_URL}show-banner.php`);
                
                console.log('Fetched data:', response.data); // Log the fetched data
                if (response.data.status === 'success') {
                    setImages(response.data.data); // Assuming `data.data` contains the image information
                } else {
                    console.error('Failed to fetch images:', response.data.message);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchImages();
    }, []);

    return (
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {images.map((_, index) => (
                    <button
                        type="button"
                        key={index}
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to={index}
                        className={index === 0 ? 'active' : ''}
                        aria-current={index === 0 ? 'true' : 'false'}
                        aria-label={`Slide ${index + 1}`}
                    ></button>
                ))}
            </div>
            <div className="carousel-inner">
                {images.map((image, index) => (
                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                        <img
                            src={`${BASE_URL}/backend/banner/${image.banner_img}`}
                            className="d-block w-100"
                            alt={`Banner ${index + 1}`}
                        />
                    </div>
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

const Section2 = () => {
    return (
        <div className="container-fluid m-0 p-0">
            <div className="minhtfst">
                <div className="col-md-12 col-12">
                    <ImageSlider />
                </div>
            </div>
        </div>
    );
};

export default Section2;