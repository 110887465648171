import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Covid() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white  mb-4 hdcsx">Covid Orphans </h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Nurturing Hope for COVID Orphans</h4>
                <div className=""></div>
                <p>In the wake of the global COVID-19 pandemic, NRIWA extends its heartfelt commitment to addressing the needs of COVID orphans. Our initiatives are centered around providing holistic support, emotional well-being, and a pathway to a brighter future for children who have lost their parents to the pandemic.</p>
            

                <h4 className="h33">Our Initiatives for COVID Orphans:</h4>

                <p><b>1. Orphan Support Programs:</b></p>
                <ul>
                    <li>NRIWA establishes comprehensive support programs tailored to the unique needs of COVID orphans. These programs encompass educational support, mental health resources, and avenues for personal development.</li>
                </ul>
                <p><b>2. Emotional and Psychological Support:</b></p>
                <ul>
                    <li>Recognizing the emotional toll on COVID orphans, NRIWA implements initiatives to provide counseling, therapy, and mental health support. Our goal is to create a nurturing environment that helps them cope with grief and build resilience.</li>
                </ul>
                <p><b>3. Foster Care and Adoption Programs:</b></p>
                <ul>
                    <li>Collaborate with authorities and NGOs to establish foster care and adoption programs for COVID orphans. NRIWA works towards creating a network of caring families and individuals who can provide a loving and stable environment for these children.</li>
                </ul>

                <h4 className="h33">How NRIWA Nurtures Hope for COVID Orphans:</h4>

                <p><b>1. Global Awareness Campaigns:</b></p>
                <ul>
                    <li>Launch global awareness campaigns to highlight the plight of COVID orphans and garner support from the international community. NRIWA aims to mobilize resources and expertise to address the multifaceted needs of these children.</li>
                </ul>
                <p><b>2. Collaboration with Child Welfare Organizations:</b></p>
                <ul>
                    <li>Collaborate with child welfare organizations and NGOs to ensure a coordinated and effective response to the needs of COVID orphans. NRIWA seeks partnerships that maximize resources and provide comprehensive care.</li>
                </ul>
                <p><b>3. Legal Advocacy for Orphan Rights:</b></p>
                <ul>
                    <li>Advocate for the legal rights of COVID orphans, working towards ensuring that they have access to inheritance, legal guardianship, and all the rights necessary for their well-being and future prospects.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for Support and Solidarity:</h4>
                <p>If you are passionate about making a positive impact in the lives of COVID orphans, connect with NRIWA. Together, we can provide the support, care, and opportunities these children need to overcome adversity and build a hopeful future.</p>
                <p>NRIWA - Nurturing Hope for COVID Orphans</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

