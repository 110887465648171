import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Agriculture() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Agriculture & Farming</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Cultivating Growth, Nurturing Farms</h4>
                <div className=""></div>
                <p>In recognition of the vital role agriculture plays in India's economy and the deep roots that many Non-Resident Indians (NRIs) have in the agricultural sector, NRIWA is dedicated to supporting and fostering sustainable agriculture practices. Our initiatives aim to connect NRIs with their agricultural heritage, provide resources for successful farming, and contribute to the growth of the agricultural community in India.</p>
            

                <h4 className="h33">Our Agricultural and Farming Initiatives:</h4>

                <p><b>1. Agricultural Education and Training:</b></p>
                <ul>
                    <li>NRIWA believes in empowering NRIs with the knowledge and skills necessary for successful and sustainable farming. We offer educational programs, workshops, and training sessions covering modern agricultural practices, organic farming, and agribusiness management.</li>
                </ul>
                <p><b>2. Farming Technology Integration:</b></p>
                <ul>
                    <li>Stay abreast of the latest advancements in agricultural technology. NRIWA collaborates with experts to introduce NRIs to innovative farming practices, precision agriculture, and the integration of technology to enhance productivity.</li>
                </ul>
                <p><b>3. Farmer Support Programs:</b></p>
                <ul>
                    <li>NRIWA extends support programs for farmers, providing access to resources such as seeds, fertilizers, and modern equipment. Our goal is to bolster the agricultural community and uplift the livelihoods of farmers across India.</li>
                </ul>

                <h4 className="h33">How NRIWA Supports Agriculture and Farming:</h4>

                <p><b>1. Expert Consultation:</b></p>
                <ul>
                    <li>Connect with agricultural experts and consultants for personalized guidance on crop selection, soil management, irrigation methods, and other aspects of farming.</li>
                </ul>
                <p><b>2. Community Engagement:</b></p>
                <ul>
                    <li>Participate in community forums and discussion groups, fostering a sense of camaraderie among NRIs involved or interested in agriculture. Share experiences, seek advice, and collaborate with fellow members.</li>
                </ul>
                <p><b>3.	Agricultural Entrepreneurship:</b></p>
                <ul>
                    <li>Explore opportunities for agricultural entrepreneurship. NRIWA supports NRIs in establishing agribusiness ventures, linking them with potential investors and markets.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for Agricultural Excellence:</h4>
                <p>NRIWA is your gateway to reconnecting with the roots of Indian agriculture. Whether you are an experienced farmer, an aspiring agripreneur, or simply passionate about sustainable farming, NRIWA invites you to join our community. Let's cultivate growth together and contribute to the flourishing agricultural landscape of India.</p>
                <p>NRIWA - Nurturing Dreams, Connecting Futures</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

