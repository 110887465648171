import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Education() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Education & Job Placement</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">Welcome to NRIWA - Nurturing Dreams, Connecting Futures</h4>
                <div className=""></div>
                <p>At NRIWA, we understand the unique challenges and aspirations for People residing abroad who seek to connect with opportunities in India. As a dedicated NGO, our mission is to serve as a bridge between NRIs and the diverse array of services available in India, with a primary focus on education, career orientation, and job placement.</p>
            

                <h4 className="h33">Our Commitment:</h4>

                <p><b>1. Education Guidance:</b></p>
                <ul>
                    <li>Navigating the Indian education system from abroad can be overwhelming. NRIWA is here to provide comprehensive guidance on educational institutions, courses, and career paths available in India.</li>
                    <li>Our expert consultants offer personalized assistance, ensuring that NRIs make informed decisions about their educational pursuits.</li>
                </ul>
                <p><b>2. Career Orientation:</b></p>
                <ul>
                    <li>Embarking on a career journey in India requires insight into the job market, industry trends, and skill requirements. NRIWA provides specialized career orientation programs to help NRIs align their skills with the evolving job landscape.</li>
                    <li>From identifying potential sectors to mapping out career trajectories, we empower NRIs to make strategic choices that align with their aspirations.</li>
                </ul>
                <p><b>3. Job Placement Services:</b></p>
                <ul>
                    <li>NRIWA collaborates with leading companies and organizations in India to facilitate seamless job placements for NRIs returning home.</li>
                    <li>Our network extends across various industries, allowing us to match skilled professionals with rewarding employment opportunities.</li>
                </ul>

                <h4 className="h33">How NRIWA Works:</h4>

                <p><b>1.	Consultation Services:</b></p>
                <ul>
                    <li>Schedule a one-on-one consultation with our experienced advisors to discuss your educational and career goals.</li>
                    <li>Receive personalized advice and recommendations tailored to your unique circumstances and aspirations.</li>
                </ul>
                <p><b>2.	Resourceful Workshops:</b></p>
                <ul>
                    <li>Participate in our informative workshops covering topics such as education trends, skill development, and effective job searching strategies.</li>
                    <li>Gain insights from industry experts and successful professionals who have navigated similar paths.</li>
                </ul>
                <p><b>3.	Placement Support:</b></p>
                <ul>
                    <li>Avail our job placement support services, connecting you with potential employers and assisting you throughout the application and interview process.</li>
                    <li>Benefit from our extensive network to explore diverse career opportunities.</li>
                </ul>

                <h4 className="h33">Contact NRIWA Today:</h4>
                <p>Whether you are considering pursuing higher education in India, exploring career options, or seeking job placements, NRIWA is your trusted partner in this journey. Connect with us today to unlock a world of opportunities and make a seamless transition to your home country, India.</p>
                <p>NRIWA - Nurturing Dreams, Connecting Futures</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

