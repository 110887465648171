import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Whychoose() {
    return (

        <>
<Header />


<div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white  mb-4">Why Choose NRIWA?</h3>   
            </div>
        </div>



{/* <div className="">
            <div className="bgimage-about">
                <img src={img7} className="img-fluid imgabout"></img>
            </div>
        </div> */}

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33">About Us</h4>
                <div className=""></div>
                <p>Welcome to the NRI Welfare Association (NRIWA), a distinguished organization committed to serving the unique needs and challenges faced by Non-Resident Indians (NRIs) across various domains. At NRIWA, our mission is to provide comprehensive consultations, support, and solutions to address the concerns of NRIs and facilitate a seamless experience in India.</p>


                <h4 className="h33">1. Expert Guidance:</h4>
                <p>At NRIWA, we take pride in our team of experienced professionals who specialize in addressing the unique needs and challenges faced by Non-Resident Indians (NRIs). Our experts are dedicated to providing you with insightful guidance and comprehensive solutions in various domains.</p>

                <h4 className="h33">2. Comprehensive Services:</h4>
                <p>NRIWA stands out for offering a wide range of services that cover every aspect of an NRI's life and interests. From education and healthcare to taxation, matrimonial matters, and social causes, we provide a holistic approach to ensure your well-being and success.</p>

                <h4 className="h33">3. Community Support:</h4>
                <p>Joining NRIWA means becoming part of a supportive and engaged community. We understand the importance of a connected network, and we provide a platform for NRIs to share experiences, seek advice, and foster a sense of belonging.</p>

                <h4 className="h33">4. Tailored Solutions:</h4>
                <p>Every NRI has unique circumstances and challenges. NRIWA is committed to delivering personalized solutions that cater to your specific needs. Our consultations and services are crafted to address the individual concerns of each member.</p>

                <h4 className="h33">5. Social Impact:</h4>
                <p>Beyond individual assistance, NRIWA is dedicated to making a positive impact on society. By actively participating in social causes such as hunger alleviation, supporting COVID orphans, and contributing to cancer treatments, we channel our collective efforts for a greater good.</p>

                <h4 className="h33">6. Transparency and Integrity:</h4>
                <p>We uphold the values of transparency and integrity in all our interactions. NRIWA is committed to building trust and maintaining open communication, ensuring that our members can rely on us for honest and ethical support.</p>

                <h4 className="h33">7. Networking Opportunities:</h4>
                <p>NRIWA organizes events, forums, and initiatives to facilitate networking among NRIs. Connect with like-minded individuals, expand your professional circle, and build valuable relationships within our community.</p>

                <h4 className="h33">8. Dedication to Your Success:</h4>
                <p>Your success as an NRI is our success. NRIWA is dedicated to being your partner in navigating the complexities of living abroad, empowering you to achieve your goals and aspirations.</p>


                <p>Join NRIWA and experience the difference of being part of a community that truly cares about your well-being and success. Discover the support, guidance, and opportunities that set us apart.</p>

            </div>



        </div>
        <Footer />
        </>
    )
}

