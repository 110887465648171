import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Matrimonial() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Matrimonial</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Fostering Connections, Celebrating Unions</h4>
                <div className=""></div>
                <p>Embarking on the journey of matrimony is a significant milestone, and NRIWA is dedicated to facilitating meaningful connections for Non-Resident Indians (NRIs) seeking life partners. Our matrimonial services are designed to bring together like-minded individuals, fostering relationships that celebrate shared values, cultural compatibility, and a commitment to a joyous future.</p>
            

                <h4 className="h33">Our Matrimonial Services:</h4>

                <p><b>1. Cultural Compatibility:</b></p>
                <ul>
                    <li>NRIWA understands the importance of cultural compatibility in a matrimonial match. Our platform provides a space for NRIs to connect with individuals who share similar cultural backgrounds, values, and traditions.</li>
                </ul>
                <p><b>2. Personalized Matchmaking:</b></p>
                <ul>
                    <li>Our dedicated team works to understand the unique preferences and aspirations of NRIs, facilitating personalized matchmaking that goes beyond superficial criteria to ensure genuine connections.</li>
                </ul>
                <p><b>3. Verified Profiles:</b></p>
                <ul>
                    <li>NRIWA prioritizes the security and authenticity of our members. All profiles undergo thorough verification processes, offering a secure and trustworthy environment for individuals seeking life partners.</li>
                </ul>
                <p><b>4. Relationship Guidance:</b></p>
                <ul>
                    <li>Navigating the complexities of relationships can be challenging. NRIWA offers guidance and support to couples, promoting healthy communication and understanding to build strong, enduring partnerships.</li>
                </ul>
                <p><b>5. Community Events:</b></p>
                <ul>
                    <li>Engage in community events organized by NRIWA, providing opportunities for NRIs to meet and connect in person. These events foster a sense of community and facilitate organic connections.</li>
                </ul>

                <h4 className="h33">How NRIWA Facilitates Matrimonial Connections:</h4>

                <p><b>1.	Profile Creation:</b></p>
                <ul>
                    <li>Create a detailed profile on the NRIWA platform, highlighting your interests, values, and what you are looking for in a life partner.</li>
                </ul>
                <p><b>2.	Matchmaking Algorithms:</b></p>
                <ul>
                    <li>Our advanced matchmaking algorithms analyze profiles to suggest potential matches based on compatibility factors, increasing the likelihood of finding a suitable life partner.</li>
                </ul>
                <p><b>3.	Interactive Features:</b></p>
                <ul>
                    <li>Utilize interactive features on our platform, such as chat rooms, video calls, and messaging, to get to know potential matches better and build a connection.</li>
                </ul>

                <p><b>4.	Guidance from Relationship Experts:</b></p>
                <ul>
                    <li>Benefit from guidance and counseling sessions with relationship experts associated with NRIWA, ensuring that your journey towards matrimony is supported by professional insights.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for Matrimonial Bliss:</h4>
                <p>Your quest for a life partner is a journey we are honored to be a part of. NRIWA is committed to creating a platform where NRIs can discover meaningful connections, leading to fulfilling and joyous matrimonial unions. Join NRIWA today and embark on the path to matrimonial bliss.</p>
                <p>NRIWA - Fostering Connections, Celebrating Unions</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

