import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Pagenotfound() {
    return (

        <>

        <Header />
        
         

        <div className="container">
        <section class="page_404">
	<div class="container">
		<div class="row justify-content-center">	
		 
		<div class="col-12 col-md-10 text-center">
		<div class="four_zero_four_bg">
			<h1 class="text-center ">404</h1>
		
		
		</div>
		
		<div class="contant_box_404">
		<h3 class="h2">
		This Page Is Under Development
		</h3>
		
		<p>the page you are looking for Is Under Development!</p>
		
		
        <Link to="/" className="link_404">
        Go to Home
    </Link>
	</div>
		</div>
		 
		</div>
	</div>
</section>
        </div>
        

        <Footer />
     
        </>
    )
}

