import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function OrganTransplant() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Organ Transplant</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Gift of Life: Organ Transplant and Donation Advocacy</h4>
                <div className=""></div>
                <p>In our pursuit of holistic well-being, NRIWA recognizes the profound impact that organ transplant and donation can have on saving lives and improving the quality of life for those in need. Our advocacy in this crucial area aims to raise awareness, dispel myths, and encourage (NRIs) to embrace the noble act of organ donation.</p>
            

                <h4 className="h33">Our Organ Transplant and Donation Initiatives:</h4>

                <p><b>1. Awareness Campaigns:</b></p>
                <ul>
                    <li>NRIWA spearheads awareness campaigns to educate NRIs about the significance of organ donation. Our goal is to dispel misconceptions, promote understanding, and highlight the life-changing impact that organ transplants can have.</li>
                </ul>
                <p><b>2. Support for Transplant Recipients:</b></p>
                <ul>
                    <li>Extend support to NRIs in need of organ transplants. NRIWA facilitates connections between potential donors and recipients, providing a platform for those seeking a second chance at life.</li>
                </ul>
                <p><b>3. Organ Donor Registry:</b></p>
                <ul>
                    <li>Advocate for the establishment of an organ donor registry within the NRI community. NRIWA encourages NRIs to register as organ donors, creating a pool of potential donors to meet the increasing demand for life-saving transplants.</li>
                </ul>

                <h4 className="h33">How NRIWA Encourages Organ Transplant and Donation:</h4>

                <p><b>1. Community Workshops:</b></p>
                <ul>
                    <li>Host community workshops where medical professionals discuss the science behind organ transplantation, the donation process, and the impact it can have on saving lives within the NRI community.</li>
                </ul>
                <p><b>2. Partnerships with Healthcare Institutions:</b></p>
                <ul>
                    <li>Collaborate with healthcare institutions to streamline the organ donation process for NRIs. NRIWA aims to establish partnerships that facilitate the seamless coordination of organ transplants and donations.</li>
                </ul>
                <p><b>3. Legal Guidance:</b></p>
                <ul>
                    <li>Provide legal guidance on organ donation procedures and documentation for NRIs. NRIWA assists individuals in understanding the legal aspects of organ donation and ensures compliance with relevant regulations.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for Organ Transplant Advocacy:</h4>
                <p>Join NRIWA in our mission to promote the gift of life through organ transplantation and donation. By fostering awareness, dispelling myths, and creating a supportive environment, we strive to build a community that embraces the transformative power of this selfless act.</p>
                <p>NRIWA - Gift of Life: Organ Transplant and Donation Advocacy</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

