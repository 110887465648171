import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function ImportExport() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Import And Export</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Global Trade Facilitation: Import and Export Initiatives</h4>
                <div className=""></div>
                <p>Embracing the global economic landscape, NRIWA is dedicated to facilitating seamless import and export activities for the Non-Resident Indian (NRI) community. Our initiatives aim to provide guidance, regulatory support, and networking opportunities to NRIs involved in international trade, fostering economic growth and global collaborations.</p>
            

                <h4 className="h33">Our Import and Export Initiatives:</h4>

                <p><b>1. Trade Compliance Assistance:</b></p>
                <ul>
                    <li>NRIWA offers comprehensive guidance on trade compliance regulations. We ensure that NRIs are well-informed about import and export laws, tariffs, and documentation requirements, facilitating smooth and legal transactions.</li>
                </ul>
                <p><b>2. Networking Platforms:</b></p>
                <ul>
                    <li>Establish networking platforms that connect NRIs involved in import and export activities. NRIWA aims to create a collaborative environment where businesses can forge partnerships, explore new markets, and enhance their global presence.</li>
                </ul>
                <p><b>3. Trade Finance Advisory:</b></p>
                <ul>
                    <li>Provide trade finance advisory services to assist NRIs in navigating financial aspects of international trade. NRIWA collaborates with financial institutions to offer insights on letters of credit, export financing, and risk mitigation strategies.</li>
                </ul>

                <h4 className="h33">How NRIWA Supports Import and Export:</h4>

                <p><b>1. Customs and Documentation Support:</b></p>
                <ul>
                    <li>NRIWA assists NRIs in understanding customs procedures and documentation requirements. Our support ensures that import and export transactions comply with international standards and regulations.</li>
                </ul>
                <p><b>2. Market Research and Intelligence:</b></p>
                <ul>
                    <li>Conduct market research and provide market intelligence reports to help NRIs make informed decisions about target markets, pricing strategies, and product positioning in the global marketplace.</li>
                </ul>
                <p><b>3. Logistics and Supply Chain Optimization:</b></p>
                <ul>
                    <li>Collaborate with logistics partners to optimize supply chain processes for import and export. NRIWA aims to enhance efficiency, reduce costs, and streamline the movement of goods across borders.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for Global Trade Success:</h4>
                <p>Whether you are a seasoned exporter/importer or just beginning your journey in international trade, NRIWA is here to support your endeavors. Join our community to access valuable resources, connect with global trade experts, and participate in initiatives that promote successful import and export activities.</p>
                <p>NRIWA - Global Trade Facilitation: Import and Export Initiatives</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

