import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Handicap() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Handicap Relef</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Empowering Differently-Abled Lives</h4>
                <div className=""></div>
                <p>NRIWA recognizes the unique challenges faced by individuals with disabilities and is dedicated to fostering inclusivity, empowerment, and support for the differently-abled. Our initiatives focus on breaking barriers, promoting accessibility, and creating a society where every individual, regardless of their abilities, can thrive.</p>
            

                <h4 className="h33">Our Initiatives for Individuals with Disabilities:</h4>

                <p><b>1. Accessibility Advocacy:</b></p>
                <ul>
                    <li>NRIWA advocates for increased accessibility in public spaces, transportation, and digital platforms to ensure that individuals with disabilities can navigate the world with ease and independence.</li>
                </ul>
                <p><b>2. Inclusive Education Programs:</b></p>
                <ul>
                    <li>Collaborate with educational institutions to implement inclusive education programs. NRIWA aims to create an environment where differently-abled individuals have equal access to education, enabling them to reach their full potential.</li>
                </ul>
                <p><b>3. Health and Well-being Support:</b></p>
                <ul>
                    <li>Provide healthcare resources and support services tailored to the needs of individuals with disabilities. NRIWA aims to address the holistic well-being of the differently-abled, including physical health, mental health, and rehabilitation.</li>
                </ul>

               

                <h4 className="h33">Connect with NRIWA for Inclusive Empowerment:</h4>
                <p>If you are passionate about promoting inclusivity and empowering the differently-abled, connect with NRIWA. Together, we can work towards breaking down barriers, creating opportunities, and building a world where every individual can lead a fulfilling and empowered life.</p>
                <p>NRIWA - Empowering Differently-Abled Lives</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

