import Header from "../components/Header.js";
import Footer from "../components/Footer.js";

import React, { useState, useEffect } from "react";

export default function legal() {
  return (
    <>
      <Header />

      <div className="container-fluid bg-breadcrumb bg-pattern ">
        <div className="container text-center ">
          <h3 className="text-white mb-4 hdcsx">LEGAL SERVICES</h3>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5">
          <h4 className="h33 hover-underline-animation">
            NRIWA - LEGAL SERVICES
          </h4>
          <div className=""></div>
          <p>
            we provide a wide range of legal services designed to meet the
            diverse needs of our clients. Our experienced team of legal
            professionals is committed to offering expert guidance and
            representation in various areas of law. Our services include:
          </p>

         

          <p>
            <b>1.Free Consultation:</b>
          </p>
          <ul>
            <li>
              Begin with a no-obligation consultation to discuss your legal
              concerns and receive initial guidance on your case.
            </li>
          </ul>
          <p>
            <b>2. All Types of Civil and Criminal Litigations:</b>
          </p>
          <ul>
            <li>
              Expert representation and legal strategies for resolving disputes
              in civil and criminal matters, ensuring your rights are protected
              throughout the legal process.
            </li>
          </ul>
          <p>
            <b>3. All Family Disputes Including Property Disputes:</b>
          </p>
          <ul>
            <li>
            Comprehensive legal support for resolving family-related conflicts, including property disputes, ensuring fair and equitable outcomes.
            </li>
          </ul>
          <p>
            <b>4. Relationship Guidance:</b>
          </p>
          <ul>
            <li>
              Navigating the complexities of relationships can be challenging.
              NRIWA offers guidance and support to couples, promoting healthy
              communication and understanding to build strong, enduring
              partnerships.
            </li>
          </ul>


          <p>
            <b>5. Matrimonial Disputes:</b>
          </p>
          <ul>
            <li>
            Specialized legal assistance for resolving matrimonial disputes, including divorce, alimony, child custody, and maintenance issues, with a focus on achieving amicable solutions.
            </li>
          </ul>


          <p>
            <b>6. Drafting and Registration of Documents:</b>
          </p>
          <ul>
            <li>
            Expert services for drafting and registering important legal documents such as sale deeds, gift deeds, wills, and agreements to sale. Ensure your documents are legally sound and properly executed.
            </li>
          </ul>


          <p>
            <b>4. Relationship Guidance:</b>
          </p>
          <ul>
            <li>
              Navigating the complexities of relationships can be challenging.
              NRIWA offers guidance and support to couples, promoting healthy
              communication and understanding to build strong, enduring
              partnerships.
            </li>
          </ul>
          <p>
            <b>5. Registration of Trade Marks, Trusts, Companies, and Firms:</b>
          </p>
          <ul>
            <li>
            Comprehensive support for the registration of trademarks to protect your brand, and the establishment of trusts, companies, and firms to facilitate smooth and legal business operations.
            </li>
          </ul>



          <p>
            <b>6. Labour and Industrial Disputes:</b>
          </p>
          <ul>
            <li>
            Legal representation and advice on matters related to labor and industrial disputes, including employment contracts, wrongful termination, and workers' rights.
            </li>
          </ul>

          <p>
            <b>7. Arbitration & Conciliation Matters: </b>
          </p>
          <ul>
            <li>
            Efficient resolution of disputes through arbitration and conciliation, offering a more flexible and quicker alternative to traditional litigation.
            </li>
          </ul>

          <p>
            <b>8. Disputes Related to Banking Laws: </b>
          </p>
          <ul>
            <li>
            Handling legal issues related to banking laws, including loan disputes, recovery proceedings, and other banking-related matters.
            </li>
          </ul>
          <p>
            <b>9. All Types of Legal Services for Defense/Pursuit of Litigations: </b>
          </p>
          <ul>
            <li>
            Dedicated legal services for defending or pursuing cases before the Hon’ble Supreme Court of India, all High Courts, District Courts, Mufid courts, Labour & Industrial Courts, DRT, Consumer Forums, NCLT, and other relevant legal forums.
            </li>
          </ul>

         
 
           
          <p>
          Our dedicated team is here to provide you with professional legal advice and representation, ensuring your legal needs are met with the utmost care and expertise. Contact us today to discuss how we can assist you with your legal matters.
          </p>


<hr/>
<div>
  <strong>Name : Adv Ranjit Sardey</strong><br/>
  <strong>Office Address : Plot No. 39, 'Mahalaxmi Appt', Flat No. 202, Opp. Tajshree Honda Showroom Ramkrishana Nagar, Khamla, Road, Nagpur-15</strong><br/>
  <strong>Email : rsardeylawassociates@gmail.com</strong><br/>
  <strong>Phone : +91 937 006 9895 </strong><br/>
  </div>

           
        </div>
      </div>

      <Footer />
    </>
  );
}
