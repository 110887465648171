import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Contact1 from "../components/Contact1";
import Slider from "../components/Slider";
import Section2 from "../components/Section2";
import logo from "../components/img/logo2.png";
import ban1 from "../components/ban1.webp";
import img3 from "../components/img/img3.png";
import s1 from "../components/img/s1.avif";
import s2 from "../components/img/s2.jpg";
import s3 from "../components/img/s3.png";
import s4 from "../components/img/s4.webp";
import s5 from "../components/img/s5.jpg";
import s6 from "../components/img/s6.jpg";
import s7 from "../components/img/s7.jpg";
import s8 from "../components/img/s8.jpg";
import s9 from "../components/img/s9.avif";
import s10 from "../components/img/s10.avif";
import s11 from "../components/img/s11.jpg";
import s12 from "../components/img/s12.jpg";
import s13 from "../components/img/s13.jpg";
import s14 from "../components/img/s14.jpg";
import s15 from "../components/img/s15.avif";
import s16 from "../components/img/leagal.jpg";
import pg1 from "../components/img/logonew.png";
import {
  faSchool,
  faKitMedical,
  faReceipt,
  faMoneyBill,
  faRing,
} from "@fortawesome/free-solid-svg-icons";

export default function Home() {
  return (
    <>
      <Header />

      <Section2 />

      <center>
        <div className="container mgtop1 p-0 d-none d-lg-block">
          <div className="row justify-content-center">
            <div className="col-md-2 col-6 col-lg-2 col-sm-6 col-xl-2 bg1">
              <img src={s1} className="img-fluid img1"></img>
              <div className="ictxt">
                <i>Educational Excellence</i>
              </div>
            </div>

            <div className="col-md-2 col-6 col-lg-2 col-sm-6 col-xl-2 bg1">
              <img src={s2} className="img-fluid img1"></img>
              <div className="ictxt">
                <i>Healthcare</i>
              </div>
            </div>

            <div className="col-md-2 col-6 col-lg-2 col-sm-6 col-xl-2 bg1">
              <img src={s3} className="img-fluid img1"></img>
              <div className="ictxt">
                <i>Taxation & CA</i>
              </div>
            </div>

            <div className="col-md-2 col-6 col-lg-2 col-sm-6 col-xl-2 bg1">
              <img src={s4} className="img-fluid img1"></img>
              <div className="ictxt">
                <i>Matrimonial</i>
              </div>
            </div>

            <div className="col-md-2 col-6 col-lg-2 col-sm-6 col-xl-2 bg1">
              <img src={s5} className="img-fluid img1"></img>
              <div className="ictxt">
                <i>Agriculture & Farming</i>
              </div>
            </div>

            <div className="col-md-2 col-6 col-lg-2 col-sm-6 col-xl-2 bg1">
              <img src={s11} className="img-fluid img1"></img>
              <div className="ictxt">
                <i>Import & Export</i>
              </div>
            </div>
          </div>
        </div>
      </center>

      <div className="container-fluid mt-5 px-5">
        <div className="row">
          <div className="col-md-8 col-12 col-lg-8  col-xl-8 ">
            <h2 className="h33 hdcsx mb-3">NRI WELFARE ASSOCIATION</h2>
            <p>
              Welcome to the NRI Welfare Association (NRIWA), a distinguished
              organization committed to serving the unique needs and challenges
              faced by Non-Resident Indians across various domains. At NRIWA,
              our mission is to provide comprehensive consultations, support,
              and solutions to address the concerns of and facilitate a seamless
              experience in India.
            </p>
            <p>
              Empowering NRIs by offering guidance and assistance in key areas
              such as{" "}
              <b>
                Education, Health care, Taxation, Matrimonial Matters,
                Agriculture, Research and Development, Old-Age Homes, Organ
                Transplants and Donations, Real Estate, Business Setup, Import
                and Export, Hunger Alleviation, Support for COVID Orphans,
                Cancer Treatments,
              </b>{" "}
              and more.
            </p>
            <a href="#" className="btn btn-warning">
              Read More
            </a>
          </div>
          <div className="col-md-4 col-12 col-lg-4  col-xl-4">
            <div className="image_frame image_item no_link scale-with-grid">
              <img src={pg1} className="img-fluid"></img>
            </div>
          </div>
        </div>
      </div>

      <Contact1 />

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 mb-5 ">
            <div className="my-auto px-5 pt-5 mt-5 text-center">
              <h1 className="h1extra ft50">Specialized Services for NRIs</h1>
              <p className="pfst"></p>
              <p className="mt-2">
                Ready To Work With Us?{" "}
                <span className="pcol">Let's Work together.</span>{" "}
              </p>
            </div>
          </div>


          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s16} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">LEGAL SERVICES</div>
            <div className="text-center my-3">
              We offer expert legal assistance and representation to navigate
              complex legal matters. From providing guidance on contracts and
              compliance to handling disputes and litigation, our experienced
              attorneys are dedicated to delivering tailored solutions that
              protect your interests and uphold your rights.
            </div>
            <Link to="/legal" className="btn btn-warning">
              Read More
            </Link>
          </div>


          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s3} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">
              Taxation &#38; CA Consultation
            </div>
            <div className="text-center my-3">
              NRIWA provides personalized tax planning services tailored to your
              unique financial situation. Our experienced CAs guide you through
              the intricacies of NRI taxation,..
            </div>
            <Link to="/Tax" className="btn btn-warning">
              Read More
            </Link>
          </div>


          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s1} className="img-fluid img2"></img>

              {/* <FontAwesomeIcon className="faextra" icon={faSchool} /> */}
            </div>
            <div className="text-center icntxt my-3">
              Educational Excellence
            </div>
            <div className="text-center my-3">
              Navigating the Indian education system from abroad can be
              overwhelming. NRIWA is here to provide comprehensive guidance on
              educational institutions, courses, and career paths available in
              India.
            </div>
            <Link to="/Education" className="btn btn-warning">
              Read More
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s2} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">Healthcare</div>
            <div className="text-center my-3">
              NRIWA understands the challenges NRIs face in accessing healthcare
              services from abroad. Our telehealth services bring the expertise
              of Indian medical professionals.
            </div>
            <Link to="/Healthcare" className="btn btn-warning">
              Read More
            </Link>
          </div>

          

          

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s4} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">Matrimonial</div>
            <div className="text-center my-3">
              NRIWA understands the importance of cultural compatibility in a
              matrimonial match. Our platform provides a space for NRIs to
              connect with individuals who share similar cultural backgrounds,
              values, and traditions.
            </div>
            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s5} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">
              Agriculture &#38; Farming
            </div>
            <div className="text-center my-3">
              NRIWA believes in empowering NRIs with the knowledge and skills
              necessary for successful and sustainable farming.
            </div>
            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s6} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">
              Research &#38; Development
            </div>
            <div className="text-center my-3">
              NRIWA establishes an Innovation Hub to serve as a collaborative
              platform for NRIs involved in research, technology, and
              innovation.{" "}
            </div>

            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s7} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">
              Old-Age &#38; Retirement Homes
            </div>
            <div className="text-center my-3">
              NRIWA envisions creating senior living communities that offer a
              comfortable and supportive environment for elderly NRI.
            </div>

            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s8} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">Organ Transplant</div>
            <div className="text-center my-3">
              NRIWA spearheads awareness campaigns to educate NRIs about the
              significance of organ donation. Our goal is to dispel
              misconceptions, promote understanding, and highlight the
              life-changing impact that organ transplants can have.
            </div>

            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s9} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">
              Housing &#38; Real E-State
            </div>
            <div className="text-center my-3">
              NRIWA offers comprehensive real estate advisory services to guide
              NRIs through the intricacies of the Indian property market.{" "}
            </div>

            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s10} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">
              Business Set-up &#38; Collaboration
            </div>
            <div className="text-center my-3">
              NRIWA establishes business incubation programs to nurture startups
              and innovative ventures.{" "}
            </div>

            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s11} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">Import &#38; Export</div>
            <div className="text-center my-3">
              NRIWA offers comprehensive guidance on trade compliance
              regulations. We ensure that NRIs are well-informed about import
              and export laws, tariffs, and documentation....
            </div>

            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s12} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">Hunger-Free Nagpur</div>
            <div className="text-center my-3">
              NRIWA establishes community food banks strategically located
              across Nagpur. These banks serve as accessible resources where
              individuals and families facing food shortages can obtain ...
            </div>

            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s13} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">Covid-Orphan</div>
            <div className="text-center my-3">
              NRIWA establishes comprehensive support programs tailored to the
              unique needs of COVID orphans.{" "}
            </div>

            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s14} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">Handicaped</div>
            <div className="text-center my-3">
              NRIWA advocates for increased accessibility in public spaces,
              transportation, and digital platforms to ensure that individuals
              with disabilities can navigate the world with ease and
              independence.
            </div>

            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>

          <div className="col-md-3 text-center mb-4 bxservice">
            <div className="text-center">
              <img src={s15} className="img-fluid img2"></img>
            </div>
            <div className="text-center icntxt my-3">Cancer Treatment</div>
            <div className="text-center my-3">
              NRIWA launches awareness campaigns to educate the community about
              the importance of early cancer detection. We emphasize regular
              screenings, symptoms recognition, and preventive measures....
            </div>

            <Link to="/" className="btn btn-warning" disbled>
              Comming Soon
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
