import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Housing() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Housing & Real E-State</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Building Dreams, Nurturing Homes</h4>
                <div className=""></div>
                <p>In the pursuit of providing a stable foundation for (NRIs), NRIWA extends its commitment to facilitating seamless access to housing and real estate opportunities in India. Our initiatives are designed to empower NRIs in making informed decisions about property investments, homeownership, and real estate transactions.</p>
            

                <h4 className="h33">Our Housing and Real Estate Initiatives:</h4>

                <p><b>1. Real Estate Advisory Services:</b></p>
                <ul>
                    <li>NRIWA offers comprehensive real estate advisory services to guide NRIs through the intricacies of the Indian property market. Our experts provide insights on market trends, legal procedures, and investment opportunities.</li>
                </ul>
                <p><b>2. Homeownership Programs:</b></p>
                <ul>
                    <li>Facilitate homeownership programs that cater to the unique needs of NRIs. NRIWA collaborates with builders, developers, and financial institutions to offer exclusive housing solutions and financing options.</li>
                </ul>
                <p><b>3. Legal Assistance:</b></p>
                <ul>
                    <li>Provide legal assistance to NRIs navigating property transactions. NRIWA ensures that NRIs are well-informed about legal requirements, documentation, and regulatory compliance when buying or selling real estate in India.</li>
                </ul>

                <h4 className="h33">How NRIWA Supports Housing and Real Estate:</h4>

                <p><b>1. Property Discovery Platforms:</b></p>
                <ul>
                    <li>Partner with property discovery platforms to create a centralized resource for NRIs to explore and evaluate real estate options across different regions in India.</li>
                </ul>
                <p><b>2. Transparent Real Estate Transactions:</b></p>
                <ul>
                    <li>Advocate for transparent and streamlined real estate transactions. NRIWA works towards creating an environment where NRIs can confidently engage in property transactions with a clear understanding of the processes involved.</li>
                </ul>
                <p><b>3. Community Development Projects:</b></p>
                <ul>
                    <li>Collaborate on community development projects that enhance the overall infrastructure and livability of residential areas. NRIWA supports initiatives that contribute to the growth and well-being of communities in India.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for Real Estate Excellence:</h4>
                <p>Whether you are considering property investment, seeking homeownership, or exploring real estate opportunities in India, NRIWA is your trusted partner. Connect with us to access expert advice, exclusive housing programs, and a supportive community dedicated to making your housing dreams a reality.</p>
                <p>NRIWA - Building Dreams, Nurturing Homes</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

