import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faSchool, faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../components/img/logo2.png';
import { Link } from 'react-router-dom';
import Donatemodal from '../components/Donatemodal';
import s8 from '../components/img/qr2.jpeg';

export default function Header() {
    // Function to handle downloading the image
    const handleDownload = () => {
        // Create a temporary anchor element
        const link = document.createElement('a');
        // Set the href attribute to the image source
        link.href = s8;
        // Set the download attribute to prompt download
        link.download = 'donateqr.jpeg';
        // Simulate a click on the anchor element
        document.body.appendChild(link);
        link.click();
        // Clean up by removing the anchor element
        document.body.removeChild(link);
    };

    return (
        <>
        
        <nav className="navbar navbar-expand-lg py-0 bg-white sticky-top hdshd">
  <div className="container">
  <Link to="/" className="navbar-brand pcol">
  <img src={logo} className="logo" alt="Logo"></img>
</Link>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className=""><FontAwesomeIcon className="faextra2" icon={faBars} /></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item nwhead">
        <Link to="/" className="nav-link text-dark active" aria-current="page">
          Home
        </Link>
        </li>
        
        <li className="nav-item dropdown nwhead">
          <a className="nav-link dropdown-toggle text-dark " href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           About
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <li><Link to="/about" className="dropdown-item">About NRIWA</Link></li>
          <li><Link to="/Whychoose" className="dropdown-item">Why Choose NRIWA</Link></li>
          <li><Link to="/Team" className="dropdown-item">Team</Link></li>
          {/* <li><Link to="/Pagenotfound" className="dropdown-item">Partners</Link></li> */}
          </ul>
        </li>

        
        <li className="nav-item dropdown nwhead">
  <a className="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdownMenuLink" role="button" aria-expanded="false">
    Our Work
  </a>
  <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
  <li><Link to="/Education" className="dropdown-item">Education & Placement</Link></li>
  <li><Link to="/Healthcare" className="dropdown-item">Healthcare</Link></li>
  <li><Link to="/Tax" className="dropdown-item">Taxation & Consultation</Link></li>
  <li><Link to="/Matrimonial" className="dropdown-item">Matrimonial</Link></li>
  <li><Link to="/Agriculture" className="dropdown-item">Agriculture & Farming</Link></li>
  <li><Link to="/Research" className="dropdown-item">Research And Development</Link></li>
  <li><Link to="/Oldage" className="dropdown-item">Oldage Homes</Link></li>
  <li><Link to="/OrganTransplant" className="dropdown-item">Organ Transplants</Link></li>
  <li><Link to="/Housing" className="dropdown-item">Housing & Real E-State</Link></li>
  <li><Link to="/Business" className="dropdown-item">Business Setups</Link></li>
  <li><Link to="/ImportExport" className="dropdown-item">Import & Export</Link></li>
  <li><Link to="/Hungerfree" className="dropdown-item">Hunger Free Nagpur</Link></li>
  <li><Link to="/Covid" className="dropdown-item">Covid-Orphan</Link></li>
  <li><Link to="/Handicap" className="dropdown-item">Handicaped</Link></li>
  <li><Link to="/Cancer" className="dropdown-item">Cancer Treatment</Link></li>

  </ul>
</li>



<li className="nav-item nwhead">
<Link to="/contact" className="nav-link text-dark">
Contact Us
    </Link>

         
        </li>



      </ul>

      <button className="btn btn-warning" data-bs-toggle="modal" data-bs-target="#exampleModal">Donate Now</button>
    </div>
  </div>
</nav>


<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Donate</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <img src={s8} className="img-fluid"></img>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={handleDownload}>Download QR Code</button>
      </div>
    </div>
  </div>
</div>



        </>
    )
}
