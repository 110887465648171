import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Hungerfree() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Hunger Free Nagpur</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Alleviating Hunger, Nurturing Nagpur</h4>
                <div className=""></div>
                <p>In our commitment to community welfare, NRIWA strives to contribute to the vision of a Hunger-Free Nagpur. Our initiatives focus on eradicating hunger, addressing food insecurity, and fostering a sustainable environment where every individual in Nagpur has access to nutritious meals.</p>
            

                <h4 className="h33">Our Hunger-Free Nagpur Initiatives:</h4>

                <p><b>1. Community Food Banks:</b></p>
                <ul>
                    <li>NRIWA establishes community food banks strategically located across Nagpur. These banks serve as accessible resources where individuals and families facing food shortages can obtain essential groceries and nutritional supplies.</li>
                </ul>
                <p><b>2. Meal Distribution Programs:</b></p>
                <ul>
                    <li>Implement meal distribution programs that provide hot, nutritious meals to vulnerable populations, including children, elderly citizens, and individuals experiencing homelessness. NRIWA collaborates with local partners to ensure the efficient execution of these programs.</li>
                </ul>
                <p><b>3. Education and Awareness Campaigns:</b></p>
                <ul>
                    <li>Launch campaigns to raise awareness about food insecurity and its impact on the community. NRIWA educates the public about available resources, nutrition, and the importance of collective efforts in building a Hunger-Free Nagpur.</li>
                </ul>

                <h4 className="h33">How NRIWA Nurtures a Hunger-Free Nagpur:</h4>

                <p><b>1. Collaboration with NGOs and Government Agencies:</b></p>
                <ul>
                    <li>Collaborate with local NGOs, government agencies, and other stakeholders to align efforts and resources towards the common goal of alleviating hunger in Nagpur.</li>
                </ul>
                <p><b>2. Technology Integration for Food Distribution:</b></p>
                <ul>
                    <li>Leverage technology to streamline the distribution process of food resources. NRIWA explores innovative solutions, such as mobile applications and online platforms, to efficiently connect donors, beneficiaries, and volunteers.</li>
                </ul>
                <p><b>3. Partnerships with Educational Institutions:</b></p>
                <ul>
                    <li>Forge partnerships with educational institutions to involve students in hunger-alleviation initiatives. NRIWA encourages educational institutions to participate in food drives, awareness campaigns, and community outreach programs.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for a Hunger-Free Nagpur:</h4>
                <p>If you share the vision of a Hunger-Free Nagpur and want to contribute to this noble cause, connect with NRIWA. Together, we can create a community where no one goes to bed hungry, and every individual in Nagpur has the opportunity to lead a healthy and fulfilling life.</p>
                <p>NRIWA - Alleviating Hunger, Nurturing Nagpur</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

