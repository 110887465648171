import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function About() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">About NRIWA</h3>   
            </div>
        </div>


        <div className="container">
            <div className="row mt-5">
                <h4 className="h33">About Us</h4>
                <div className=""></div>
                <p>Welcome to the NRI Welfare Association (NRIWA), a distinguished organization committed to serving the unique needs and challenges faced by Non-Resident Indians (NRIs) across various domains. At NRIWA, our mission is to provide comprehensive consultations, support, and solutions to address the concerns of NRIs and facilitate a seamless experience in India.</p>
            

                <h4 className="h33">Our Vision</h4>
                <p>To be a trusted partner and advocate for the welfare of Non-Resident Indians, fostering a connected and supportive community.</p>

                <h4 className="h33">Our Mission</h4>
                <p>Empowering NRIs by offering guidance and assistance in key areas such as education, healthcare, taxation, matrimonial matters, agriculture, research and development, old-age homes, organ transplants and donations, real estate, business setup, import and export, hunger alleviation, support for COVID orphans, cancer treatments, and more.</p>


                <h4 className="h33">What Sets Us Apart</h4>
                <p><b>Expert Consultations:</b> Our team of experts provides personalized consultations to address the specific needs and challenges faced by NRIs, offering valuable insights and guidance.</p>
                <p><b>Comprehensive Services: </b>NRIWA covers a wide spectrum of services to ensure a holistic approach to the well-being of NRIs, encompassing various aspects of their lives and interests.</p>
                <p><b>Community Support:</b>We foster a sense of community among NRIs, providing a platform for networking, sharing experiences, and supporting each other in navigating the complexities of living abroad.</p>
                <p><b>Dedication to Social Causes:</b> NRIWA actively contributes to social causes, including hunger eradication, supporting COVID orphans, and contributing to cancer treatments, reflecting our commitment to making a positive impact.</p>
          
                

                <h4 className="h33">Join Us on the Journey</h4>
                <p>Whether you are an NRI seeking guidance or support, or an individual passionate about contributing to social causes, NRIWA welcomes you to join us on this journey. Together, we aim to build a strong, connected, and empowered community.</p>
                <p>For more information, explore our website or reach out to us. NRIWA Your Partner in Nurturing a Brighter Future.</p>

            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

