import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Tax() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white  mb-4 hdcsx">Taxation & Consultation</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Navigating Taxation Seamlessly with Expert CA Consultation</h4>
                <div className=""></div>
                <p>Understanding the intricacies of taxation is crucial for Non-Resident Indians (NRIs) who have financial ties to India. NRIWA is your trusted partner in navigating the complex landscape of taxation, offering expert Chartered Accountant (CA) consultation services to ensure compliance, optimize financial strategies, and maximize returns.</p>
            

                <h4 className="h33">Our Taxation & CA Consultation Services:</h4>

                <p><b>1. Tax Planning and Compliance:</b></p>
                <ul>
                    <li>NRIWA provides personalized tax planning services tailored to your unique financial situation. Our experienced CAs guide you through the intricacies of NRI taxation, helping you optimize your financial affairs while ensuring compliance with Indian tax laws.</li>
                </ul>
                <p><b>2. Investment Guidance:</b></p>
                <ul>
                    <li>Receive expert advice on tax-efficient investment strategies. Our CAs analyze the tax implications of various investment options, helping you make informed decisions that align with your financial goals.</li>
                </ul>
                <p><b>3. NRI Property Taxation:</b></p>
                <ul>
                    <li>Understand the tax implications of property ownership in India. NRIWA assists in navigating property-related tax matters, including rental income, capital gains, and compliance with property tax regulations.</li>
                </ul>
                <p><b>4. Repatriation of Funds:</b></p>
                <ul>
                    <li>Our CA consultation services extend to facilitating the repatriation of funds from India, ensuring compliance with Reserve Bank of India (RBI) guidelines and optimizing the repatriation process.</li>
                </ul>
                <p><b>5. GST and Business Taxation:</b></p>
                <ul>
                    <li>For NRIs involved in business activities in India, NRIWA provides expert guidance on Goods and Services Tax (GST) compliance and other business-related taxation matters.</li>
                </ul>

                <h4 className="h33">How NRIWA and CA Consultation Can Benefit You:</h4>

                <p><b>1.	Comprehensive Tax Assessment:</b></p>
                <ul>
                    <li>Our CAs conduct a thorough assessment of your financial situation, ensuring that all applicable tax implications are identified and addressed.</li>
                </ul>
                <p><b>2.	Customized Tax Strategies:</b></p>
                <ul>
                    <li>Receive personalized tax strategies designed to minimize liabilities and maximize returns, taking into account both Indian and international tax regulations.</li>
                </ul>
                <p><b>3.	Regular Tax Updates:</b></p>
                <ul>
                    <li>Stay informed about changes in tax laws and regulations affecting NRIs through our regular updates and newsletters, keeping you ahead of any compliance requirements.</li>
                </ul>

                <p><b>4.	Timely Filing and Compliance:</b></p>
                <ul>
                    <li>NRIWA ensures timely filing of tax returns and compliance with all regulatory requirements, providing you with peace of mind and avoiding any potential penalties.</li>
                </ul>

                <h4 className="h33">Contact NRIWA Today for Taxation & CA Consultation:</h4>
                <p>Navigating taxation as an NRI doesn't have to be daunting. NRIWA and our team of expert Chartered Accountants are here to guide you through the process, offering reliable consultation services to optimize your financial standing in India. Connect with us today for personalized taxation and CA consultation tailored to your unique needs.</p>
                <p>NRIWA - Navigating Taxation Seamlessly with Expert CA Consultation</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

