import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Oldage() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white  mb-4 hdcsx">Oldage Homes</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Nurturing Elders, Creating Home</h4>
                <div className=""></div>
                <p>As we embrace the values of compassion and filial responsibility, NRIWA extends its commitment to caring for the elderly within the (NRI) community. Our focus on establishing old-age and care homes aims to provide a nurturing environment for seniors, ensuring their well-being and happiness during their golden years.</p>
            

                <h4 className="h33">Our Old-Age and Care Home Initiatives:</h4>

                <p><b>1. Senior Living Communities:</b></p>
                <ul>
                    <li>NRIWA envisions creating senior living communities that offer a comfortable and supportive environment for elderly NRIs. These communities prioritize independence, social engagement, and healthcare to enhance the quality of life for our elders.</li>
                </ul>
                <p><b>2. Holistic Care Programs:</b></p>
                <ul>
                    <li>Implement holistic care programs that address the physical, emotional, and mental well-being of residents. NRIWA partners with healthcare professionals to provide tailored care plans, fitness programs, and mental health support.</li>
                </ul>
                <p><b>3. Cultural Sensitivity:</b></p>
                <ul>
                    <li>Acknowledging the importance of cultural ties, our old-age and care homes are designed to be culturally sensitive, fostering an atmosphere where residents can connect with their roots, traditions, and fellow residents who share similar backgrounds.</li>
                </ul>

                <h4 className="h33">How NRIWA Cares for the Elderly:</h4>

                <p><b>1. Personalized Care Plans:</b></p>
                <ul>
                    <li>Each resident receives a personalized care plan tailored to their unique needs and preferences, ensuring that their specific requirements are met with utmost attention and care.</li>
                </ul>
                <p><b>2. Community Engagement:</b></p>
                <ul>
                    <li>Fostering a sense of community, NRIWA encourages social interaction among residents. Shared spaces, communal activities, and support groups create an environment where meaningful connections flourish.</li>
                </ul>
                <p><b>3. Family Integration Programs:</b></p>
                <ul>
                    <li>Recognizing the importance of family, NRIWA implements programs that facilitate family visits, virtual connectivity, and involvement in the care process, bridging the gap between NRIs and their elderly family members.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for Elderly Care:</h4>
                <p>NRIWA invites you to join us in championing the well-being of our elderly community members. Together, let's create a haven that cherishes the wisdom, experiences, and contributions of our seniors. Connect with NRIWA to be a part of our mission to provide compassionate care and comfort in the golden years.</p>
                <p>NRIWA - Nurturing Elders, Creating Home</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

