import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocation, faPhone, faMailForward, faMarker, faCopyright } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import logo from '../components/img/logo2.png';

export default function Contact1() {
  const currentYear = new Date().getFullYear();

    return (
        <>
        <div className="bg-light py-5 mt-5">
            <div className="container">
            <div className="row ">

                <div className="col-md-8 col-12 col-xl-8 col-lg-8">
                  <h4 className="text-dark">Discover the Best fit for your Investment With NRIWA's free Consultation</h4>
                  
                  <div className="text-dark">To be a trusted partner and advocate for the welfare of Non-Resident Indians, fostering a connected and supportive community.</div>
                
                </div>
                <div className="col-md-4 col-12 col-xl-4 col-lg-4 mx-auto">
                <a href="#" className="btn btn-warning">Become a Member</a>
                </div>
                
            </div>
            </div>
            
        </div>

        </>
    )
}