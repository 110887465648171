import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Research() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Research & Development</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Pioneering Progress through Research and Development</h4>
                <div className=""></div>
                <p>In an era of innovation and progress, NRIWA recognizes the transformative power of research and development (R&D) in shaping the future of various sectors. Our commitment to fostering growth extends to initiatives that promote R&D, ensuring that NRI actively contribute to advancements in science, technology, and other domains.</p>
            

                <h4 className="h33">Our Research and Development Initiatives:</h4>

                <p><b>1. Innovation Hub for NRIs:</b></p>
                <ul>
                    <li>NRIWA establishes an Innovation Hub to serve as a collaborative platform for NRIs involved in research, technology, and innovation. This hub fosters an environment where ideas flourish, and cutting-edge solutions are developed.</li>
                </ul>
                <p><b>2. Collaborative Research Projects:</b></p>
                <ul>
                    <li>Facilitate collaboration between NRIs and research institutions in India. NRIWA acts as a conduit for joint research projects, bringing together diverse talents to address challenges and explore new frontiers in various fields.</li>
                </ul>
                <p><b>3. Technology Transfer Initiatives:</b></p>
                <ul>
                    <li>Bridge the gap between innovation and practical application. NRIWA promotes technology transfer initiatives, helping translate research findings into real-world solutions that benefit communities across India.</li>
                </ul>

                <h4 className="h33">How NRIWA Facilitates Research and Development:</h4>

                <p><b>1. Research Networks:</b></p>
                <ul>
                    <li>Join research networks established by NRIWA, connecting NRIs with like-minded individuals, experts, and institutions to foster collaborative research efforts.</li>
                </ul>
                <p><b>2. Research Conclaves and Symposia:</b></p>
                <ul>
                    <li>Participate in research conclaves and symposia organized by NRIWA. These events provide a platform for NRIs to present their research findings, exchange ideas, and engage in meaningful discussions.</li>
                </ul>
                <p><b>3. Innovation Challenges:</b></p>
                <ul>
                    <li>NRIWA launches innovation challenges addressing specific societal or technological issues. Engage in these challenges to showcase your innovative solutions and contribute to positive change.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for Research Excellence:</h4>
                <p>NRIWA invites NRIs passionate about research and development to join us in pioneering progress. Together, let's build a community that advances knowledge, fosters innovation, and contributes to the betterment of society. Connect with NRIWA to be a part of the journey towards a brighter, more innovative future.</p>
                <p>NRIWA - Pioneering Progress through Research and Development</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

