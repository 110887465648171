import Header from '../components/Header';
import Footer from '../components/Footer.js';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import img7 from '../components/img/img7.jpg';



export default function Cancer() {
    return (

        <>

        <Header />

        <div className="container-fluid bg-breadcrumb bg-pattern ">
            <div className="container text-center ">
                <h3 className="text-white mb-4 hdcsx">Cancer Treatment</h3>   
            </div>
        </div>

        <div className="container">
            <div className="row mt-5">
                <h4 className="h33 hover-underline-animation">NRIWA - Hope and Healing: Cancer Treatment Advocacy</h4>
                <div className=""></div>
                <p>In the pursuit of comprehensive healthcare and well-being, NRIWA is committed to advocating for accessible and effective cancer treatment for the Non-Resident Indian (NRI) community. Our initiatives focus on raising awareness, providing support, and collaborating with healthcare institutions to ensure that individuals facing cancer receive the care and resources they need.</p>
            

                <h4 className="h33">Our Cancer Treatment Advocacy Initiatives:</h4>

                <p><b>1. Awareness and Early Detection Campaigns:</b></p>
                <ul>
                    <li>NRIWA launches awareness campaigns to educate the community about the importance of early cancer detection. We emphasize regular screenings, symptoms recognition, and preventive measures to improve overall cancer outcomes.</li>
                </ul>
                <p><b>2. Support for Cancer Patients and Caregivers:</b></p>
                <ul>
                    <li>Establish support programs for cancer patients and their caregivers. NRIWA provides emotional support, counseling services, and resources to navigate the challenges associated with cancer diagnosis and treatment.</li>
                </ul>
                <p><b>3. Collaboration with Oncology Centers:</b></p>
                <ul>
                    <li>Collaborate with leading oncology centers and hospitals to ensure that NRIs have access to state-of-the-art cancer treatment facilities. NRIWA aims to establish partnerships that facilitate affordable and quality care.</li>
                </ul>

                <h4 className="h33">How NRIWA Supports Cancer Treatment:</h4>

                <p><b>1. Patient Navigation Services:</b></p>
                <ul>
                    <li>Provide patient navigation services to guide individuals through the complex healthcare system. NRIWA assists in coordinating appointments, obtaining medical records, and connecting patients with the appropriate specialists.</li>
                </ul>
                <p><b>2. Cancer Survivorship Programs:</b></p>
                <ul>
                    <li>Establish survivorship programs to support individuals post-cancer treatment. NRIWA focuses on the holistic well-being of cancer survivors, addressing physical, emotional, and social aspects of life after cancer.</li>
                </ul>
                <p><b>3. 4Community Health Education:</b></p>
                <ul>
                    <li>Organize community health education programs to dispel myths, provide accurate information about different types of cancers, and empower NRIs to make informed decisions about their health.</li>
                </ul>

                <h4 className="h33">Connect with NRIWA for Cancer Advocacy:</h4>
                <p>If you or your loved ones are affected by cancer or if you are passionate about cancer advocacy, connect with NRIWA. Together, we can work towards a future where cancer is detected early, treated effectively, and individuals facing cancer receive the support and care they deserve.</p>
                <p>NRIWA - Hope and Healing: Cancer Treatment Advocacy</p>
                
            </div>
        </div>
        

        <Footer />
     
        </>
    )
}

